import React, { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

import Spinner from '../../common/components/Spinner/Spinner';
import RouterWrapper from '../RouterWrapper';
import { ROUTE_PATH } from '../route-paths';
import MwaRedirect from '../../pages/Redirects/mwa-redirect';

const callerRoleRoutes: RouteObject[] = [
  {
    path: ROUTE_PATH.mwaRedirect,
    element: (
      <Suspense fallback={<Spinner />}>
        <MwaRedirect />
      </Suspense>
    ),
  },
];

const CallerRoleRouter: React.FC = () => {
  return <RouterWrapper routes={callerRoleRoutes} defaultPath={ROUTE_PATH.mwaRedirect} />;
};

export default CallerRoleRouter;
