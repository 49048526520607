import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import invariant from 'tiny-invariant';

import Spinner from '../common/components/Spinner/Spinner';
import { FormattedUser } from '../common/http/hooks/user';
import { UserProvider } from '../contexts/user-context';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout';
import PeerActiveRouter from './routers/PeerActiveRouter';
import PeerApplicationRouter from './routers/PeerApplicationRouter';
import UnauthenticatedRouter from './routers/UnauthenticatedRouter';
import PeerUpgradingRouter from './routers/PeerUpgradingRouter';
import { useActiveCall } from '../common/http/hooks/active-call';
import ListenerActiveInCallRouter from './routers/ListenerActiveInCallRouter';
import ListenerActiveNotInCallRouter from './routers/ListenerActiveRouter';
import useAuth from '../common/hooks/useAuth';
import CallerRoleRouter from './routers/CallerRoleRouter';
import AdminRoleRouter from './routers/AdminRoleRouter';

const ListenerActiveRouter: React.FC = () => {
  const activeCallQuery = useActiveCall();

  if (activeCallQuery.isLoading) return <Spinner />;
  if (activeCallQuery.data) return <ListenerActiveInCallRouter />;
  return <ListenerActiveNotInCallRouter />;
};

const AuthenticatedRouter: React.FC<{ user: FormattedUser }> = ({ user }) => {
  const renderRouter = (): React.ReactElement => {
    invariant(user, 'User must be defined');

    if (user.listenerRole) {
      switch (user.listenerRole.state) {
        case 'onboarding_peer':
        case 'rejected_peer':
          return <PeerApplicationRouter />;
        case 'active_peer':
          return <PeerActiveRouter />;
        case 'upgrading_peer':
          return <PeerUpgradingRouter />;
        case 'active_listener':
          return <ListenerActiveRouter />;
        default:
          throw new Error(`User is in invalid state: ${user.listenerRole.state}`);
      }
    } else if (user.callerRoleId) {
      return <CallerRoleRouter />;
    } else if (user.isAdmin) {
      return <AdminRoleRouter />;
    } else {
      return <UnauthenticatedRouter />;
    }
  };

  return (
    <UserProvider user={user}>
      <AuthenticatedLayout>{renderRouter()}</AuthenticatedLayout>
    </UserProvider>
  );
};

const GlobalRouter: React.FC = () => {
  const { user, userIsLoading } = useAuth();

  // set a timer on this. If it never works, send them to a sorry somethings wrong page
  if (userIsLoading) {
    return <Spinner />;
  }

  const router = user ? <AuthenticatedRouter user={user} /> : <UnauthenticatedRouter />;
  const browserRouter = createBrowserRouter([{ path: '*', element: router }]);

  return <RouterProvider router={browserRouter} />;
};

export default GlobalRouter;
