import React, { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

import Spinner from '../../common/components/Spinner/Spinner';
import { SupportPage } from '../../pages/Support/Support';
import { TermsPage } from '../../pages/Terms/Terms';
import { ROUTE_PATH } from '../route-paths';
import RouterWrapper from '../RouterWrapper';
import AboutMe from '../../pages/AboutMe/AboutMe';

const BankingInformation = lazy(() => import('../../pages/BankingInformation/BankingInformation'));
const Experiences = lazy(() => import('../../pages/Experiences/Experiences'));
const ListenerHome = lazy(() => import('../../pages/Home/ListenerHome'));
const ListenerPayouts = lazy(() => import('../../pages/ListenerPayouts/ListenerPayouts'));
const ListeningAcademy = lazy(() => import('../../pages/ListeningAcademy/ListeningAcademy'));
const Lobby = lazy(() => import('../../pages/Lobby/Lobby'));
const PostCallPage = lazy(() => import('../../pages/PostCall/PostCallPage'));
const Profile = lazy(() => import('../../pages/Profile/Profile'));
const TraitTags = lazy(() => import('../../pages/Tags/TraitTags'));
const Roles = lazy(() => import('../../pages/Roles/Roles'));
const SchedulePage = lazy(() => import('../../pages/Schedule/SchedulePage'));

export const listenerActiveRoutes: RouteObject[] = [
  {
    path: ROUTE_PATH.home.home,
    element: (
      <Suspense fallback={<Spinner />}>
        <ListenerHome />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.profile,
    element: (
      <Suspense fallback={<Spinner />}>
        <Profile />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.roles,
    element: (
      <Suspense fallback={<Spinner />}>
        <Roles />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.schedule,
    element: (
      <Suspense fallback={<Spinner />}>
        <SchedulePage />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.experiences,
    element: (
      <Suspense fallback={<Spinner />}>
        <Experiences />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.tags,
    element: (
      <Suspense fallback={<Spinner />}>
        <TraitTags />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.reports,
    element: (
      <Suspense fallback={<Spinner />}>
        <ListenerPayouts />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.aboutMe,
    element: (
      <Suspense fallback={<Spinner />}>
        <AboutMe />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.lobby,
    element: (
      <Suspense fallback={<Spinner />}>
        <Lobby />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.banking,
    element: (
      <Suspense fallback={<Spinner />}>
        <BankingInformation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.listenerAcademy,
    element: (
      <Suspense fallback={<Spinner />}>
        <ListeningAcademy />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.terms,
    element: (
      <Suspense fallback={<Spinner />}>
        <TermsPage />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.postCall,
    element: (
      <Suspense fallback={<Spinner />}>
        <PostCallPage />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.support,
    element: (
      <Suspense fallback={<Spinner />}>
        <SupportPage />
      </Suspense>
    ),
  },
];

const ListenerActiveNotInCallRouter: React.FC = () => (
  <RouterWrapper routes={listenerActiveRoutes} defaultPath={ROUTE_PATH.home.home} />
);

export default ListenerActiveNotInCallRouter;
