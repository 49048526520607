import React, { Suspense, lazy } from 'react';
import { RouteObject, Navigate } from 'react-router-dom';
import Spinner from '../../common/components/Spinner/Spinner';
import ForgetPassword from '../../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import { ClientOnboarding } from '../../pages/ClientOnboarding/ClientOnboarding';
import { ROUTE_PATH } from '../route-paths';
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import RouterWrapper from '../RouterWrapper';
import Verification from '../../pages/Verification/Verification';
import LoginGuard from '../routerGuards/LoginGuard';

const Terms = lazy(() => import('../../pages/Terms/Terms'));
const Login = lazy(() => import('../../pages/Login/Login'));
const PeerCreateAccount = lazy(() => import('../../pages/CreateAccount/PeerCreateAccount'));
const PeerIntro = lazy(() => import('../../pages/Intro/PeerIntro'));

const unauthenticatedRoutes: RouteObject[] = [
  {
    path: ROUTE_PATH.landingPage,
    element: (
      <Suspense fallback={<Spinner />}>
        <PeerIntro />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.login,
    element: (
      <Suspense fallback={<Spinner />}>
        <LoginGuard>
          <Login />
        </LoginGuard>
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.forgotPassword,
    element: (
      <Suspense fallback={<Spinner />}>
        <ForgetPassword />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.resetPassword,
    element: (
      <Suspense fallback={<Spinner />}>
        <ResetPassword />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.intro.peer,
    element: (
      <Suspense fallback={<Spinner />}>
        <PeerIntro />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.terms,
    element: (
      <Suspense fallback={<Spinner />}>
        <Terms />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.create.listener,
    element: <Navigate to={ROUTE_PATH.create.peer} />,
  },
  {
    path: ROUTE_PATH.create.peer,
    element: (
      <Suspense fallback={<Spinner />}>
        <PeerCreateAccount />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.verify,
    element: (
      <Suspense fallback={<Spinner />}>
        <Verification />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.onboarding.clientOnboardingRoute,
    element: (
      <Suspense fallback={<Spinner />}>
        <ClientOnboarding />
      </Suspense>
    ),
  },
];

const UnauthenticatedRouter: React.FC = () => {
  return (
    <UnauthenticatedLayout>
      <RouterWrapper routes={unauthenticatedRoutes} defaultPath={ROUTE_PATH.landingPage} />
    </UnauthenticatedLayout>
  );
};

export default UnauthenticatedRouter;
