import React from 'react';
import { Box } from '@mui/material';
import GlobalRouter from './routing/GlobalRouter';
import { AuthProvider } from './contexts/auth-context';

const App: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderColor: theme.palette.background.kindlygray1,
          },
        })}
        minHeight="100vh"
        display="flex"
        width="100%"
        maxWidth="500px"
        flexDirection="column"
      >
        <AuthProvider>
          <GlobalRouter />
        </AuthProvider>
      </Box>
    </Box>
  );
};

export default App;
