import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  IconButton,
  Link as MuiLink,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import logo from '../../assets/images/kindly-human-logo-2-line.svg';
import { CreateAccountRole } from '../../common/http/hooks/create-account';
import { ROUTE_PATH } from '../../routing/route-paths';
import { ProgressMobileStepper } from '../../common/components/CreateAccount/ProgressMobileStepper';
import TermsAndConditionModal from './TermsAndConditionModal';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import { SignInButton } from '../../common/components/SignInButton';

export const onboardingSteps = [
  { text: 'Account', stepNumber: 1 },
  { text: 'Profile', stepNumber: 2 },
  { text: 'Experience', stepNumber: 3 },
];

export interface CreateAccountFormData {
  emailAddress: string;
}

export interface TermsNavState {
  emailAddress: string;
  password: string;
  role: CreateAccountRole;
}

const createAccountSchema = yup.object().shape({
  emailAddress: yup.string().required('Email is required').email('Must be valid email'),
});

const CreateAccountForm: React.FC<{
  onSubmit: (formData: CreateAccountFormData) => void;
  error?: string | null;
  removeError: () => void;
}> = ({ onSubmit, error }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateAccountFormData>({
    resolver: yupResolver(createAccountSchema),
  });
  const [openTermsAndConditionModal, setOpenTermsAndConditionModal] = useState(false);
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        color="primary.contrastText"
        alignItems="center"
        p={2}
        pb={5}
        mb={4}
        sx={{ background: ({ palette: { background } }) => background.patterned }}
      >
        <Box width="100%" display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" component={Link} to={ROUTE_PATH.landingPage} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
        <img src={logo} />
      </Box>

      <Box mx={3}>
        <ProgressMobileStepper steps={onboardingSteps} stepCount={4} currentStep={1} />
        <Typography fontWeight={800} component="h1" variant="h5">
          Create your account
        </Typography>
        <Box mt={3} />
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <TextField
            fullWidth
            type="email"
            autoComplete="email"
            label="EMAIL"
            error={Boolean(errors.emailAddress)}
            variant="outlined"
            helperText={errors.emailAddress?.message}
            {...register('emailAddress')}
          />
          <Box mt={3} />

          <Box mt={4} />
          {error && (
            <Grid mt={4} item>
              <Typography variant="body1" textAlign="center" color={(theme) => theme.palette.error.main}>
                {error}
              </Typography>
            </Grid>
          )}
          <Grid display="flex" px="12px" py="26px" justifyContent="center" item>
            <FormControlLabel
              label=""
              sx={{ margin: 0 }}
              control={
                <Checkbox
                  color="primary"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />
            <Typography
              display="flex"
              alignItems="center"
              px={2}
              sx={{ fontWeight: 400, fontSize: '18px', display: 'inline' }}
            >
              I have read and accept these{' '}
              <Box
                sx={{ fontWeight: 800, display: 'inline', color: '#1D1183' }}
                onClick={() => setOpenTermsAndConditionModal(true)}
              >
                Terms & Conditions
              </Box>{' '}
              and{' '}
              <Box
                sx={{ fontWeight: 800, display: 'inline', color: '#1D1183' }}
                onClick={() => setOpenPrivacyPolicyModal(true)}
              >
                Privacy Policy
              </Box>
              .
            </Typography>
          </Grid>
          <Box width="100%" display="flex" justifyContent="center">
            <Button type="submit" disabled={!checked} variant="contained">
              Submit
            </Button>
          </Box>
        </form>
        <Box mt={3} />
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          <Typography>Already have an account?</Typography>
          <SignInButton color='secondary.contrastText' />
        </Box>
      </Box>
      <TermsAndConditionModal close={() => setOpenTermsAndConditionModal(false)} open={openTermsAndConditionModal} />
      <PrivacyPolicyModal close={() => setOpenPrivacyPolicyModal(false)} open={openPrivacyPolicyModal} />
    </>
  );
};

export default CreateAccountForm;
