import React from 'react';
import useAuth from '../../common/hooks/useAuth';

const LoginGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isKeycloakEnabled, keycloak } = useAuth();

  if (isKeycloakEnabled && keycloak) {
    keycloak?.login();
  }

  return <>{children}</>;
};

export default LoginGuard;
