import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../routing/route-paths";
import useAuth from "../hooks/useAuth";


interface SignInButtonProps {
  color?: string;
}

export const SignInButton: React.FC<SignInButtonProps> = ({color = 'primary.contrastText'}) => {
    const { isKeycloakEnabled, keycloak } = useAuth();
    const navigate = useNavigate();
  
    const handleLoginClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      /** This is used to prevent the Login page from redirecting back to this landing page when a user comes from here */
      event.preventDefault();
      if (isKeycloakEnabled && keycloak) {
        keycloak?.login();
      } else {
        navigate(ROUTE_PATH.login);
      }
    };
  
    return (
      <Button variant="text" onClick={handleLoginClick}>
        <Typography color={color}>Sign In</Typography>
      </Button>
    );
  };