import { Button, CircledArrowRightIcon, LeftArrowIcon } from '@kindlyhuman/component-library';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import {
  useAuthenticateWithToken,
  useSend2FAEmail,
  useSendKeycloakVerificationLink,
} from '../../common/http/hooks/user';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../routing/route-paths';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/images/kindly-human-logo-2-line.svg';
import { PwaFeatures, useFeatureFlag } from '../../common/http/hooks/feature-flags';
import useAuth from '../../common/hooks/useAuth';

interface VerificationPropsType {
  title?: string;
  transitionPage?: () => void;
}

const Verification: React.FC<VerificationPropsType> = () => {
  const navigate = useNavigate();
  const {logout} = useAuth();
  const send2FAMutation = useSend2FAEmail();
  const keycloakFeatureFlag = useFeatureFlag(PwaFeatures.PWA_KEYCLOAK_LOGIN);
  const isKeycloak = keycloakFeatureFlag.enabled && !keycloakFeatureFlag.isLoading;
  const hasRunOnce = useRef(false);
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');

  const emailAddress = localStorage.getItem('emailAddress')!;
  const storedUserId = localStorage.getItem('userId')!;
  const sendKeycloakVerificationLinkMutation = useSendKeycloakVerificationLink();

  const sendVerificationLink = (isResend = false) => {
    if (keycloakFeatureFlag.enabled) {
      sendKeycloakVerificationLinkMutation.mutate(
        {
          userId: storedUserId,
        },
        {
          onSuccess: () => {
            if (isResend) {
              Toast.success('Verification link sent successfully.');
              setLinkSent(true);
            }
          },
          onError: (err: any) => {
            Toast.error(err.response.data.description);
          },
        },
      );
    } else {
      send2FAMutation.mutate(
        {
          email_address: emailAddress,
        },
        {
          onSuccess: () => {
            Toast.success('Verification code sent successfully.');

            setLinkSent(true);
          },
          onError: (err: any) => {
            Toast.error(err.response.data.description);
          },
        },
      );
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;

    if (newValue === '') {
      setInputValue(newValue);
    }

    if (newValue.length <= 6 && /^[0-9]+$/.test(newValue)) {
      setInputValue(newValue);
    }
  };

  const authWithTokenMutation = useAuthenticateWithToken();
  const authenticate = (code: string) => {
    if (!emailAddress || !storedUserId) {
      Toast.error('An error occured. Please try logging in again');
      navigate(ROUTE_PATH.create.peer);
    }

    authWithTokenMutation.mutate(
      { token: code, userId: parseInt(storedUserId) },
      {
        onError: () => {
          Toast.error('Invalid code. Please try again');
          setInputValue('');
        },
      },
    );
  };

  // We want to to send the verification link as soon as the page loads, but not til we know if we're using keycloak or not
  useEffect(() => {
    if (hasRunOnce.current || keycloakFeatureFlag.isLoading) return;
    hasRunOnce.current = true;
    sendVerificationLink();
  }, [keycloakFeatureFlag.isLoading]);

  useEffect(() => {
    if (!keycloakFeatureFlag.isLoading && !storedUserId) {
      navigate(ROUTE_PATH.create.peer);
    }
  }, [keycloakFeatureFlag.isLoading, storedUserId]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        color="primary.contrastText"
        alignItems="center"
        p={2}
        pb={5}
        mb={4}
        sx={{ background: ({ palette: { background } }) => background.patterned }}
      >
        <Box width="100%" display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={() => logout()} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
        <img src={logo} />
      </Box>
      {linkSent && (!isKeycloak) ? (
        <>
          <Box p={4}>
            <button
              onClick={() => setLinkSent(false)}
              className="flex no-wrap gap-x-2 font-medium text-dark txt-lg pb-8"
            >
              <LeftArrowIcon /> Back
            </button>
            <Box py={4}>
              <Typography
                component="h2"
                variant="h5"
                fontWeight={800}
                className="text-very-dark-gray font-bold text-3xl pb-6"
              >
                Account Verification
              </Typography>
            </Box>
            <div className="text-dark leading-loose text-lg pb-4">
              We just sent you a 6-digit verification code to <span className="font-bold">{emailAddress}</span>
            </div>
            <Box pt={2}>
              <input
                className="text-center block w-full p-4 bg-gray-50 border border-gray-300
                        rounded-lg font-manrope text-sm text-gray-900 md:!text-base focus:ring-4 focus:outline:none
                         focus:ring-[#25008a]/10 focus:border-opacity-10 focus:border-[#25008a]/10"
                onChange={handleInputChange}
                type="text"
                placeholder=""
                value={inputValue}
              />
            </Box>
            <Box py={4} className="text-dark py-8 flex no-wrap items-center leading-4">
              <div className="pr-4">Didn't get a code? </div>
              <Box pt={0.5} className="flex no-wrap items-center gap-1 text-primary text-sm font-bold leading-tight">
                <button
                  data-testid="resend-2fa-button"
                  onClick={() => sendVerificationLink(true)}
                  className="pt-1 self-center mx-auto flex no-wrap gap-1 text-primary text-sm font-bold leading-tight"
                >
                  Resend
                  <CircledArrowRightIcon />
                </button>
              </Box>
            </Box>
            <Box pb={3}>
              <Button
                className="w-full"
                data-testid="submit-2fa-button"
                variant="primary"
                onClick={() => {
                  authenticate(inputValue);
                }}
              >
                Verify and Create Account
              </Button>
            </Box>
            <button
              onClick={() => navigate(ROUTE_PATH.create.peer)}
              className="pt-8 self-center mx-auto flex no-wrap items-center gap-1 text-primary text-sm font-bold leading-tight"
            >
              Change Email Address
              <CircledArrowRightIcon />
            </button>
          </Box>
        </>
      ) : (
        <>
          <Box px={4}>
            <button
              onClick={() => navigate(ROUTE_PATH.create.peer)}
              className="flex no-wrap gap-x-2 font-medium text-dark txt-lg pb-8"
            >
              <LeftArrowIcon /> Back
            </button>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box p={4}>
              <h6 className="text-dark text-lg pt-8 leading-relaxed pb-10">
                We need to verify your email. We just sent you an email at <span className="font-bold">{emailAddress}</span> — please open it and click the link to verify your account.
              </h6>
            </Box>
            <Box pb={4}>
              <Button
                className="max-w-[200px] mx-auto"
                data-testid="resend-email-button"
                variant="primary"
                onClick={() => {
                  sendVerificationLink(true);
                }}
              >
                Resend Link to my Email
              </Button>
            </Box>
            {/* The below is only applicable for MFA, not Keycloak */}
            {!isKeycloak && (
            <div className="flex justify-center pt-12">
              <button className="text-primary font-bold leading-tight" onClick={() => setLinkSent(true)}>
                I already have a code
              </button>
            </div>
          )}
          </Box>
        </>
      )}
    </>
  );
};

export default Verification;
