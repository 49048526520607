import React, { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

import Spinner from '../../common/components/Spinner/Spinner';
import RouterWrapper from '../RouterWrapper';
import { ROUTE_PATH } from '../route-paths';
import SwitchboardRedirect from '../../pages/Redirects/sb-redirect';

const adminRoleRoutes: RouteObject[] = [
  {
    path: ROUTE_PATH.switchboardRedirect,
    element: (
      <Suspense fallback={<Spinner />}>
        <SwitchboardRedirect />
      </Suspense>
    ),
  },
];

const AdminRoleRouter: React.FC = () => {
  return <RouterWrapper routes={adminRoleRoutes} defaultPath={ROUTE_PATH.switchboardRedirect} />;
};

export default AdminRoleRouter;
